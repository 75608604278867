<template>
  <div
    v-if="isPermission('currency.list')"
    class="currency-dropdown"
  >
    <v-select
      v-model="selectedCurrency"
      dense
      :label="$t('curreny')"
      :items="currencyOptions"
      item-value="code"
      item-text="code"
      @change="setCurrency"
    />
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      selectedCurrency: "PKR",
      currencyOptions: [
        { code: "USD", name: "United States Dollar", symbol: "$" },
        { code: "PKR", name: "Pakistani Rupee", symbol: "₨" },
      ],
    };
  },
  computed: {
    getSelected() {
      return localStorage.getItem("selectedCurrency");
    },
  },
  mounted() {
    if (this.isPermission("currency.list") == false) {
      localStorage.setItem("selectedCurrency", "PKR");
      this.selectedCurrency = "PKR";
    } else if (!this.getSelected) {
      this.setCurrency("PKR");
      this.selectedCurrency = "PKR";
    } else {
      this.selectedCurrency = this.getSelected;
    }
  },
  methods: {
    isPermission(val) {
      return this.$admin.can(val);
    },
    setCurrency(val) {
      localStorage.setItem("selectedCurrency", val);
      const { name, params, query } = this.$route;
      if (JSON.stringify(params) !== JSON.stringify({})) {
        this.$router.push({ name: name, params, query });
      } else {
        this.$router.push({ name: name });
      }
      window.location.reload();
    },
  },
};
</script>
<style lang="sass" scoped>
.currency-dropdown
    width: 6rem
    padding-top:1rem
</style>
